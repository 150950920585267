import React from 'react'
import LibraryWrapper from './LibraryWrapper'
import { Progress, Card } from '../../components/library'

function progress() {
    return (
        <LibraryWrapper>
            <Progress step={2} total={4} />
            <hr />
            <Card bg="purple">
                <Progress step={1} total={3} color="light" />
            </Card>
        </LibraryWrapper>
    )
}
export default progress
